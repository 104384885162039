exports.components = {
  "component---src-pages-co-delame-tsx": () => import("./../../../src/pages/co-delame.tsx" /* webpackChunkName: "component---src-pages-co-delame-tsx" */),
  "component---src-pages-dotazniky-tsx": () => import("./../../../src/pages/dotazniky.tsx" /* webpackChunkName: "component---src-pages-dotazniky-tsx" */),
  "component---src-pages-en-about-us-tsx": () => import("./../../../src/pages/en/about-us.tsx" /* webpackChunkName: "component---src-pages-en-about-us-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-our-results-tsx": () => import("./../../../src/pages/en/our-results.tsx" /* webpackChunkName: "component---src-pages-en-our-results-tsx" */),
  "component---src-pages-en-what-is-our-job-tsx": () => import("./../../../src/pages/en/what-is-our-job.tsx" /* webpackChunkName: "component---src-pages-en-what-is-our-job-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nase-vysledky-tsx": () => import("./../../../src/pages/nase-vysledky.tsx" /* webpackChunkName: "component---src-pages-nase-vysledky-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-vyzkumnehod-cz-smrtelna-dopravni-nehoda-zachycena-na-videu-tsx": () => import("./../../../src/pages/vyzkumnehod-cz-smrtelna-dopravni-nehoda-zachycena-na-videu.tsx" /* webpackChunkName: "component---src-pages-vyzkumnehod-cz-smrtelna-dopravni-nehoda-zachycena-na-videu-tsx" */)
}

